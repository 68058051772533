/* ProductDetailsModal.css */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
  }
  .modal-container {
    max-height: 85%;
    overflow: auto; 
    scrollbar-width: none; 
    -ms-overflow-style: none;
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    max-width: 90%;
    text-align: center;
    position: relative;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
  }
  .modal-container::-webkit-scrollbar {
    display: none; 
  }
  .modal-image {
    max-width: 100%;
    height: auto;
    margin-bottom: 15px;
  }
  
  .close-button {
    position: absolute;
    top: 10px;
    right: 10px;
    border: none;
    background: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .modal-container {
      width: 70%;
      max-height: 70%;
      overflow: scroll;
      padding: 15px;
    }
  
    .modal-image {
      height: auto;
    }
  
    .close-button {
      font-size: 20px;
    }
  }
  
  @media (max-width: 480px) {
    .modal-container {
      width: 55%;
      padding: 10px;
    }
  
    .modal-image {
      height: auto;
      margin-bottom: 10px;
    }
  
    .close-button {
      font-size: 18px;
    }
  }
  