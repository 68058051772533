@keyframes joinEffect {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  100% {
    transform: scale(1.1);
    opacity: 1;
  }
}


.collections-container {
  position: relative;
  text-align: center;
  margin-bottom: 5%;
}

.collections-title {
  font-size: 45px;
  margin-bottom: 40px;
  font-family: Arial, Helvetica, sans-serif;
}

.collections-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.collections-grid {
  display: flex;
  justify-content: center;
  gap: 50px; /* Adjust spacing between images */
}

.collection-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0;
  transform: scale(1);
}
.collection-card.visible {
  animation: joinEffect 2s forwards;
}

.collection-image {
  width: 250px;
  height: 300px;
  background-color: #efefefa1;
  padding: 2rem 3rem;
}

/* Initially hide the buttons */
.nav-button {
  background-color: #303030;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 1rem;
  opacity: 0; /* Hidden by default */
  transition: opacity 0.3s ease, background-color 0.3s ease;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;
}

/* Show buttons on hover */
.collections-navigation:hover .nav-button {
  opacity: 1;
}

.nav-button:hover {
  background-color: #505050;
}

.prev-button {
  left: 20px; /* Positioned 20px from the left edge */
}

.next-button {
  right: 20px; /* Positioned 20px from the right edge */
}

/* Hide navigation buttons on mobile */
@media (max-width: 768px) {
  .nav-button {
    display: none;
  }

  .collections-grid {
    display: flex;
    flex-direction: row; /* Keep the layout */
    align-items: center;
    gap: 50px; /* Adjust spacing between items */
    overflow-x: auto; /* Allow horizontal scrolling */
    scroll-snap-type: x mandatory; /* Snap to items while scrolling */
  }

  .collection-card {
    scroll-snap-align: start;
  }

  .collections-grid::after {
    content: "Swipe to see more";
    font-size: 14px;
    color: #888;
    position: absolute;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(255, 255, 255, 0.7);
    padding: 5px 10px;
    border-radius: 5px;
  }
}

/* Adjust styles for larger screens */
@media (min-width: 769px) {
  .collections-navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .nav-button {
    opacity: 0; /* Initially hidden */
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: opacity 0.3s ease;
  }

  .prev-button {
    left: 20px;
  }

  .next-button {
    right: 20px;
  }
}

