@keyframes zoomIn {
  0% {
    transform: scale(1);
    opacity: 0;
  }
  100% {
    transform: scale(1.1);
    opacity: 1;
  }
}

.hero-container {
  display: flex;
  align-items: center;
  padding: 1.5rem;
  justify-content: center; 
  background-color: #f4f4f4;
  border-radius: 10px;
  margin: 2rem auto;
  width: 95%;
  height: 480px;
  position: relative;
  animation: zoomIn 2s ease-out;
}

.hero-text {
  max-width: 50%;
  text-align: center;
}

.hero-title {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.hero-subtitle {
  font-size: 1.2rem;
  margin-bottom: 1.5rem;
}

.shop-button {
  padding: 1.3rem 2.5rem;
  background-color: black;
  color: white;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 15px;
}

.hero-image-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 40%;
}

.hero-image {
  height: 400px;
  width: 400px;
  object-fit: cover;
  border-radius: 10px;
}

.pagination {
  position: absolute; 
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 150px;
}

.prev-btn,
.next-btn {
  background-color: #333;
  color: white;
  border: none;
  padding: 0.3rem 0.7rem;
  border-radius: 30%;
  cursor: pointer;
  font-size: 1.3rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

.prev-btn:hover,
.next-btn:hover {
  background-color: #555;
  transform: scale(1.1);
}

.pagination {
  display: flex;
  justify-content: center;
  margin-top: 250px;
}

.dot {
  height: 12px;
  width: 12px;
  margin: 0 5px;
  background-color: #bbb;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.3s ease, transform 0.3s ease;
  cursor: pointer;
}

.dot.active {
  background-color: #333;
  width: 15px;
  transform: scale(1.2);
}

/* Responsive Design */
@media (max-width: 768px) {
  .hero-container {
    flex-direction: column;
    padding: 1rem;
    width: 90%;
    height: 90vh;
    margin-left: auto;
    margin-right: auto;
  }

  .hero-text {
    max-width: 100%;
  }

  .hero-title {
    font-size: 2rem;
  }

  .hero-subtitle {
    font-size: 1rem;
  }

  .hero-image {
    height: 300px;
    width: 300px;
  }

  .shop-button {
    padding: 1rem 2rem;
  }
  
  .pagination {
    margin-top: 20px;
  }
}

@media (max-width: 480px) {
  .hero-container {
    padding: 0.5rem;
    width: 95%;
    flex-direction: column;
    height: 530px;
  }
  .dot {
    height: 9px;
    width: 9px;
    margin-top: 80px;
  
  }

  .hero-title {
    font-size: 1.8rem;
    margin-bottom: 0.5rem;
  }

  .hero-subtitle {
    font-size: 0.9rem;
    margin-bottom: 1rem;
  }

  .hero-image-wrapper {
    margin-top: 10px;
    max-width: 100%;
  }

  .hero-image {
    height: 250px;
    width: 100%;
    max-width: 250px;
    margin-top: 5%;
  }

  .shop-button {
    padding: 0.8rem 1.5rem;
    font-size: 0.9rem;
  }

  .prev-btn,
  .next-btn {
    font-size: 1rem;
  }

  .pagination {
    margin-top: 60%;
  }
}
