html, body {
  height: 100%;
  margin: 0;
}

#root {
  min-height: 100%;
  position: relative;
}

.sidebar-indicator {
  position: fixed; 
  bottom: 0;
  left: 0;
  right: 0;
  width: 80%;
  background-color: #282c34; 
  padding: 10px;
  border-radius: 12px 12px 0 0; 
  box-shadow: 0 -4px 8px rgba(0, 0, 0, 0.4);
  transition: opacity 0.4s ease, transform 0.4s ease, visibility 0.4s ease;
  z-index: 999;
  border-top: 1px solid #444; 
  display: flex; 
  justify-content: flex-start; 
  overflow-x: auto; 
  white-space: nowrap;
  box-sizing: border-box; 
}

.sidebar-indicator.hidden {
  opacity: 0;
  transform: translateY(100%);
  visibility: hidden;
}

.indicator-item {
  margin: 0 10px; 
  padding: 12px 20px;
  cursor: pointer;
  transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
  border-radius: 10px; 
  color: #f0f0f0; 
  font-size: 14px; 
  white-space: nowrap; 
}

.indicator-item.active {
  transform: scale(1.2); 
  background-color: #3a3f47; 
  color: #fff;
}

.indicator-item:hover {
  background-color: #4b535a; 
  color: #fff;
}

.indicator-item:not(.active):hover {
  transform: scale(1.1); 
}

@media (min-width: 1200px) {
  .sidebar-indicator {
    justify-content: center; 
    max-width: 1200px;
    margin: 0 auto;
    
  }
}

@media (max-width: 600px) {
  .sidebar-indicator {
    padding: 10px;
    width: 80%;
   
  }

  .indicator-item {
    margin: 0 5px;
    padding: 8px 16px; 
    font-size: 12px; 
  }

  .indicator-item.active {
    transform: scale(1.2);
  }

  .indicator-item:not(.active):hover {
    transform: scale(1.1); 
  }
}

@media (max-width: 425px) {
  .sidebar-indicator {
    padding: 8px;
    width: 100%;
  }

  .indicator-item {
    margin: 0 4px;
    padding: 6px 12px;
    font-size: 10px;
  }

  .indicator-item.active {
    transform: scale(1.1);
  }

  .indicator-item:not(.active):hover {
    transform: scale(1.05); 
  }
}
