.partners-container {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffffff;
  border-radius: 30px;
  position: relative;
  overflow: hidden;
  width: 95%;
  height: 12vh; 
  margin-top: 5%;
  margin-bottom: 2%;
  left: 50%; 
  transform: translateX(-50%);
}

.partners-container:hover {
  box-shadow: 4px 4px 4px 8px rgba(0, 0, 0, 0.1);
  opacity: 1;
}

.partners-list {
  display: flex;
  align-items: center;
  justify-content: space-between; 
  width: 100%;
  overflow: hidden;
  padding-left: 10%; 
  box-sizing: border-box;
}

.partner-item {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 0 15px;
  transition: transform 0.3s ease-in-out;
}

.partner-logo {
  width: 20vh;
  height: 8vh; 
  object-fit: cover;
}

.nav-arrow {
  background-color: transparent;
  border: none;
  font-size: 2rem;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  color: #333;
  transition: color 0.3s;
}

.nav-arrow:hover {
  color: #000;
}

.left-arrow {
  left: 5%;
}

.right-arrow {
  right: 5%;
}

@media (max-width: 1200px) {
  .partners-list {
    padding-left: 5%;
  }
}

@media (max-width: 992px) {
  .partners-list {
    padding-left: 2%; 
  }
}

@media (max-width: 768px) {
  .partners-container{
    box-shadow: 4px 4px 4px 8px rgba(0, 0, 0, 0.1);
    opacity: 1;
    margin-bottom: 10%;
    margin-top: -5%;
  }
  .partners-list {
    padding-left: 0;
    justify-content: center;
    flex-wrap: wrap; 
  }

  .partner-item {
    margin: 0 5px;
  }

  .nav-arrow {
    display: none;
  }
}
