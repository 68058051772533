/* General Navbar Styling */
.navbar-container {
  display: flex;
  justify-content: space-evenly;

justify-items: "center";
  align-items: center;
  padding: 1rem;
  margin-left: 5%;
  background-color: white;
  position: relative;
  height: 10vh;
}

.logo {
  size: 10px;
  margin-left: -15%;
  margin-top: 2%;
  font-family: Arial, Helvetica, sans-serif;
}

.logo-drop {
  font-size: 1.5rem;
  font-weight: bold;
  justify-content: center;
  margin-left: 7%;
  margin-top: 10%;
  font-family: Arial, Helvetica, sans-serif;
}

.logo-img {
  width: 200px;
  height: 120px;
}

.logo-img-sidebar {
  margin-left: 20px;
  width: 200px;
  height: 120px;
}

.menu-icon {
  font-size: 1.5rem;
  cursor: pointer;
  margin-left: -55%;
  margin-top: 2.4%;
}

.search-container {
  position: relative;
  display: flex;
  width: 60%;
  justify-content: right;
}

.search-bar {
  width: 100%;
  padding: 1.1rem;
  border-radius: 40px;
  border: none;
  background-color: #f4f4f4;
}

.search-icon {
  position: absolute;
  right: 5%;
  top: 50%;
  transform: translateY(-50%);
  color: #000000;
  font-size: 20px;
}

.icon-container {
  display: flex;
  align-items: center;
  gap: 2rem;
  font-size: 20px;
}

/* Sidebar Styling */
.sidebar {
  position: fixed;
  top: 0;
  left: 370px;
  height: 100%;
  width: 370px;
  background-color: #ffffff;
  color: #000000;
  padding: 20px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
  transform: translateX(-100%); /* Start off-screen */
  transition: transform 0.5s ease-in-out;
  z-index: 10000;
}

.sidebar.open {
  transform: translateX(0);
}

.close-btn {
  background: none;
  border: none;
  color: #000000;
  font-size: 1.6rem;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 10px;
}

.sidebar-links {
  list-style: none;
  padding: 0;
  margin-left: 80px;
  font-size: 1.4rem;
  margin-top: 40px;
  font-weight: 700;
  font-family: Arial, Helvetica, sans-serif;
}

.sidebar-links li {
  margin: 20px 0;
  margin-top: 30px;
}

.sidebar-links a {
  color: #161616;
  text-decoration: none;
  position: relative;
  font-size: 1.4rem;
  font-weight: 700;
  transition: color 0.3s ease, padding-left 0.3s ease;
}

.sidebar-links a::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #f5a623;
  position: absolute;
  bottom: -5px;
  left: 0;
  transition: width 0.3s ease;
}

.sidebar-links a:hover,
.sidebar-links a.active {
  color: #f5a623;
  padding-left: 10px;
}

.sidebar-links a:hover::after,
.sidebar-links a.active::after {
  width: 100%;
}

/* Overlay Styling */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 900;
  cursor: pointer;
}

/* Search Results Styling */
.search-results {
  position: absolute;
  top: 100%;
  left: 0;
  width: 95%;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px;
  z-index: 1000;
  max-height: 300px;
  overflow-y: auto;
}

.search-result-item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  padding: 10px;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.search-result-item:hover {
  background-color: #f4f4f4;
}

.search-result-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  margin-right: 10px;
}

.search-result-info h3 {
  margin: 0;
  font-size: 16px;
}

.search-result-info p {
  margin: 2px 0;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .navbar-container {
    flex-direction: column;
    height: auto;
    padding: 0.5rem;
    justify-content: center;
  }

  .logo-img {
    width: 150px;
    height: auto;
  }

  .logo-img-sidebar {
    width: 150px;
    height: auto;
  }

  .search-container {
    width: 100%;
    margin-top: 1rem;
  }

  .icon-container {
    margin-top: 1rem;
    font-size: 1.25rem;
    gap: 1rem;
  }

  .sidebar {
    left: 35vh;
    position: fixed;
  top: 0;
  width:  45vh;
  background-color: #ffffff;
  color: #000000;
  padding: 0px;
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.3);
  transform: translateX(-100%);
  transition: transform 0.5s ease-in-out;
  z-index: 10000;
}
input:focus {
  outline: none;
  border: 1px solid #ccc; /* Optional */
}
}
.language-switcher {
  position: relative;
  display: inline-block;
  cursor: pointer;
  margin-left: 20px;
}

.language-list {
  display: none;
  position: absolute;
  background-color: white;
  list-style: none;
  padding: 0;
  margin: 0;
}

.language-switcher:hover .language-list {
  display: block;
}

.language-list li {
  padding: 8px 16px;
  cursor: pointer;
}

.language-list li:hover {
  background-color: lightgray;
}
@media (max-width: 768px) {
  .menu-icon {
    font-size: 1.5rem;
    cursor: pointer;
    margin-left: -48%;
    margin-top: 2.4%;
  }
  .logo-img {
    width: 200px;
    height: 120px;
   margin-left: 10%;
      display: flex; 
      align-items: center; 
      justify-content: center; 
    }

.search-container
{
  margin-left: 30%;
  width: 70%;
}
  
}