.inspiration-container {
  position: relative;
  text-align: center;
  margin-top: 50px;
}

.inspiration-title {
  font-size: 45px;
  margin-bottom: 40px;
  font-family: Arial, Helvetica, sans-serif;
}

.inspiration-navigation {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.inspiration-grid {
  display: flex;
  justify-content: center;
  gap: 50px;
  overflow: hidden;
  position: relative;
}

.inspiration-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  transition: transform 0.3s ease, opacity 0.3s ease;
  opacity: 0;
  height: 80vh;
  transform: scale(1);
}

.inspiration-card.visible {
  animation: joinEffect 2s forwards;
}

.inspiration-image {
  width: 350px;
  height: 300px;
  padding: 2rem 3rem;
}

.inspiration-details {
  display: block;
  margin-top: 10px;
  text-align: center;
}

.details-button {
  width: 150px;
  margin-top: 10px;
  background-color: #303030;
  color: white;
  border: none;
  border-radius: 8px;
  padding: 0;
  cursor: pointer;
  font-size: 16px;
  text-align: center;
  transition: background-color 0.3s ease;
  display: flex;
  height: 10%;
  align-items: center;
  justify-content: center;
  margin-bottom: 100px;
}

.details-button:hover {
  background-color: #686868;
}

.details-button:active {
  background-color: #505050;
}

.insp-prev-button,
.insp-next-button {
  background-color: #303030;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 15px;
  cursor: pointer;
  font-size: 1rem; /* Responsive font size */
  opacity: 0;
  transition: opacity 0.3s ease, background-color 0.3s ease;

}

.insp-prev-button:hover,
.insp-next-button:hover {
  background-color: rgba(0, 0, 0, 0.9);
}

.insp-prev-button:active,
.insp-next-button:active {
  background-color: rgba(0, 0, 0, 0.8);
}

.inspiration-container:hover .insp-prev-button,
.inspiration-container:hover .insp-next-button {
  opacity: 1;
  visibility: visible;
}

.insp-prev-button {
  position: absolute;
  left: 10px; /* Adjust for small screens */
  top: 50%;
  transform: translateY(-50%);
  z-index: 1000;}

.insp-next-button {
  position: absolute;
  right: 10px; /* Adjust for small screens */
  top: 50%;
  transform: translateY(-50%);}

.pagination-dots-insp {
  position: relative;
  bottom: 20%;
  left: 5%;
  transform: translateX(-50%);
  display: flex;
  gap: 10px;
  opacity: 1; 
  transition: opacity 0.3s ease;
  z-index: 10;
}

.dot-insp {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #ccc;
  transition: background-color 0.3s ease;
}

.dot-insp.active {
  background-color: #333;
}

.dot-insp:hover {
 cursor: pointer;
}
@media (max-width: 480px) {
  .inspiration-image {
    width: 250px;
    height: 200px;
  }

  .details-button {
    width: 100px;
    font-size: 12px;
    height: 50px;
  }

  .insp-prev-button,
  .insp-next-button {
    padding: 8px 12px;
    z-index: 10000;
    font-size: 0.8rem;
    opacity: 1; /* Ensure buttons are visible */
    visibility: visible; /* Ensure visibility */
  }

  .inspiration-grid {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 50px;
    overflow: hidden;
    position: relative;
  }

  .inspiration-container {
    margin-bottom: -80px;
  }
}
