/* General Styles */
.filter-sidebar {
  width: 80%;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #e5e5e5;
  font-family: 'Arial', sans-serif;
  transition: transform 0.3s ease, width 0.3s ease;
  position: relative;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sidebar-toggle {
  position: absolute;
  top: 20px;
  right: -60px;
  background-color: #333;
  color: #ffffff;
  border: none;
  padding: 12px 24px;
  border-radius: 30px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
  z-index: 1000;
}

.close-arrow 
{
  display: none;
}
.arrow-toggle {
  display: none;
}
@media (min-width: 769px) {
  .sidebar-toggle {
    display: none;
  }
}
@media (max-width: 768px) {

  .filter-sidebar {
    width: 35%;   overflow: hidden;
    transform: translateX(-100%);
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #e5e5e5;
    font-family: 'Arial', sans-serif;
    transition: transform 0.3s ease, width 0.3s ease;
    position: relative;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .arrow-toggle {
    display: block;
    position: absolute;
    
   
 margin-top: 16%;
    padding: 8px;
  
    cursor: pointer;
  }
  .close-arrow {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
  
  
    padding: 8px;
 
    cursor: pointer;
  }
  .filter-sidebar.open {
    width: 100%;
    transform: translateX(0);
  }
 
  
  .filter-sidebar.closed {
    width: 10px;
    transform: translateX(-100%); 
    overflow: hidden;
  }
  .filter-sidebar-container.open {
    width: 80%;
    position: absolute;
    transform: translateX(0);
  }
  .filter-sidebar-container.closed {
    width: 5%; 
  }
}


.sidebar-toggle:hover {
  background-color: #555;
  transform: translateX(10px); 
}
.filter-sidebar h3 {
  margin-top: 30px;
  margin-bottom: 20px;
  font-size: 20px;
  font-weight: 700;
  color: #333;
  border-bottom: 2px solid #f5f5f5;
  padding-bottom: 10px;
}

.categories {
  display: flex;
  flex-direction: column;
}

.category-button {
  background-color: #f5f5f5;
  border: none;
  padding: 12px 20px;
  margin-bottom: 12px;
  font-size: 18px;
  color: #666;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.3s ease;
  border-radius: 8px;
}

.category-button:hover {
  background-color: #ddd;
  transform: scale(1.02); 
}

.category-button.selected {
  background-color: #333;
  color: #ffffff;
}

.price-labels {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 16px;
  color: #666;
}

input[type="range"] {
  width: 100%;
  margin-top: 15px;
  -webkit-appearance: none;
  appearance: none;
  background: #ddd;
  height: 10px;
  border-radius: 5px;
  outline: none;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 24px;
  height: 24px;
  background: #888;
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-moz-range-thumb {
  width: 24px;
  height: 24px;
  background: #888;
  border-radius: 50%;
  cursor: pointer;
}

input[type="range"]::-ms-thumb {
  width: 24px;
  height: 24px;
  background: #888;
  border-radius: 50%;
  cursor: pointer;
}

/* Media Query for 480px or less */
@media screen and (max-width: 480px) {
  .filter-sidebar {
    width: 100%; /* Adjust width for smaller screens */
    padding: 10px;
  }

  .filter-toggle-button {
    display: block; /* Show the toggle button only on small screens */
    margin-left: 320px;
    font-size: 20px;
  }
  
.categories {
  display: flex;
  flex-direction: column;
  margin-left: 50px;
}
.category-button{
  background-color: #f5f5f5;
  border: none;
  width: 300px;
  padding: 10px 15px;
  margin-bottom: 10px;
  font-size: 16px;
  color: #666;
  text-align: left;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

  
  .filter-sidebar h3,
  .categories {
    display: none; /* Initially hide the filters on small screens */
  }
  
  /* Show filters when the toggle button is clicked */
  .filter-sidebar.active .h3,
  .filter-sidebar.active .categories {
    display: flex;
    flex-direction: column;
  }
}
