.about-us-container {
  padding: 10px;
  border-radius: 8px;
  max-width: 90%;
  margin: 20px auto;

}
.aboutus-supercontainer{
  background-color: #f9f9f9;

}
.about-us-heading {
  font-size: 2rem;
  text-align: left;
  color: #333;
  margin-bottom: 20px;
}

.about-us-content {
  display: flex;
  flex-direction: row;
  align-items: stretch; 
  gap: 20px;
}

.about-us-image {
  flex: 1; 
  border-radius: 16px;
  object-fit: cover;
}

.about-us-text {
  flex: 1; 
  text-align: left;
  font-size: 1.1rem;
  color: #555;
  line-height: 1.6;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.intro-paragraph {
  margin-bottom: 20px;
  font-size: 1.2rem;
  color: #444;
}

.about-us-list {
  list-style: none;
  padding: 0;
}

.about-us-list li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.list-icon {
  color: #2ecc71;
  margin-right: 10px;
}

.list-text {
  font-size: 1rem;
}

@media (max-width: 768px) {
  .about-us-content {
    flex-direction: column;
  }

  .about-us-image,
  .about-us-text {
    max-width: 100%;
  }

  .about-us-heading {
    font-size: 1.8rem;
  }

  .intro-paragraph {
    font-size: 1rem;
  }
}
