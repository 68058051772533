.deal-of-the-week {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto;
  padding: 20px;
  background-color: #dadada44;
}

.deal-content {
  max-width: 100%;
  text-align: center;
}

.deal-tagline {
  font-size: 18px;
  color: #000000;
  font-weight: 800;
}

.deal-title {
  font-size: 24px;
  margin: 10px 0;
}

.deal-description {
  font-size: 16px;
  margin: 10px 0;
}

.deal-button {
  background-color: #000000;
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 20px;
}

.deal-image img {
  width: 100%;
  max-width: 450px;
  height: auto;
  margin-top: 20px;
}

/* Media Queries */
@media (min-width: 768px) {
  .deal-of-the-week {
    flex-direction: row;
    align-items: center;
  }

  .deal-content {
    max-width: 50%;
    text-align: left;
  }

  .deal-title {
    font-size: 32px;
  }

  .deal-button {
    margin-top: 30px;
  }


  .deal-image img {
    margin-left: 20px;
  }
}

@media (max-width: 480px) {
  .deal-title {
    font-size: 20px;
  }

  .deal-description {
    font-size: 14px;
  }

  .deal-button {
    padding: 8px 16px;
    font-size: 14px;
  }
}
