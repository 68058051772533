.footer {
  background-color: #fff;
  color: black;
  padding: 10px;
  font-size: 14px;
  font-family: 'Playfair Display', serif;
}

.footer-container {
  display: flex;
  flex-wrap: wrap; 
  justify-content: space-between; 
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  gap: 20px;
  min-height: 20vh; 
}

.footer-info {
  flex: 1 1 200px; 
  font-family: 'Montserrat', sans-serif;
}

.footer-quick-links ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  color: black;
  gap: 20px;
  font-family: 'Lora', serif;
}

.footer-quick-links ul li {
  margin: 0;
}

.footer-quick-links ul li a {
  color: black;
  text-decoration: none;
}

.footer-quick-links ul li a:hover {
  text-decoration: underline;
}

.footer-social {
  flex: 1 1 200px;
  margin: 0; 
  font-family: 'Nunito', sans-serif;
}

.footer-social a {
  color: black;
  margin: 0 10px;
  font-size: 20px;
  text-decoration: none;
}

.footer-social a:hover {
  color: #1e90ff;
}

.footer-newsletter {
  flex: 1 1 300px;
  margin: 0; 
  font-family: 'Raleway', sans-serif;
}

.footer-newsletter p {
  margin: 0 0 10px;
}

.newsletter-form {
  display: flex;
  justify-content: center;
}

.newsletter-form input {
  padding: 10px;
  border: none;
  border-radius: 4px;
  margin-right: 10px;
  
  font-size: 14px;
  font-family: 'Raleway', sans-serif;
}

.newsletter-form button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  background-color: #1e90ff;
  color: black;

  font-size: 14px;
  cursor: pointer;
  font-family: 'Raleway', sans-serif;
}

.newsletter-form button:hover {
  background-color: #1c86ee;
}

.footer-copy {
  text-align: center;
  font-size: 12px;
  width: 100%;
  font-family: 'Lato', sans-serif;
}

body, html {
  margin: 0;
  padding: 0;
  height: 100%; 
}

@media (max-width: 480px) {
  .footer-quick-links ul {
    margin-top: -100px;
    gap: 40px;
    margin-left: 20px;
  }
  .footer-container {
    display: flex;
  
  }
  .footer {
    background-color: #333;
    color: #fff;
    padding: 10px;
    font-size: 14px;
  }
  .footer-newsletter  {
    margin-top: -120px;
  }
  .footer-newsletter p {
   text-align: center;
  }
}