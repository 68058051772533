.featured-products_home {
    text-align: center;
    margin: 20px auto;
}

.featured-products_home h2 {
    font-size: 24px;
    font-weight: bold;
}

.featured-products_home p {
    font-size: 16px;
    color: #666;
}

.category-buttons_home {
    margin: 20px 0;
}

.category-button_home {
    background: #f0f0f0;
    border: 1px solid #ddd;
    padding: 10px 20px;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 5px;
}

.category-button_home.active {
    background: #000;
    color: #fff;
}

.products-grid_home {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    margin-top: 20px;
    justify-content: center;
}

.product-card_home {
    width: 220px;
    border-radius: 8px;
    padding: 10px;
    text-align: center;
    transition: transform 0.3s ease;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.product-image-container_home {
    width: 100%;
    height: 220px; 
    overflow: hidden;
    border-radius: 8px;
    background: #f9f9f9;

    position: relative;
    display: flex; 
    justify-content: center;
    align-items: center;
}

.product-image-container_home img {
    max-width: 100%;
    max-height: 100%;
    object-fit: cover; 
    border-radius: 8px;
    display: block;
}
.product-card_home:hover .product-image-container_home img {
    transform: scale(1.1);
}
.product-details_home {
    text-align: center;
    margin-top: 10px;
}

.product-details_home h3 {
    font-size: 16px;
    margin: 10px 0;
}

.price_home {
    font-size: 18px;
    font-weight: bold;
}

.old-price_home {
    text-decoration: line-through;
    color: #999;
    font-size: 14px;
    margin-left: 5px;
}

.see-all_home {
    background: #000;
    color: #fff;
    text-decoration: none;
    padding: 10px 20px;
    border-radius: 4px;
    margin-top: 30px;
    cursor: pointer;
    font-size: 16px;
}
