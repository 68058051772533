/* src/LanguageSwitcher.css */
.language-switcher {
    position: relative;
    display: inline-block;
    margin-right: 20px !important;
    margin-top: 2%;

  }
  
  .dropdown-toggle {
    background: none;
    border: none;
    cursor: pointer;
    font-size: 1.5rem;
  
    transition: color 0.3s;
  }
.globe-icon {
  color: balck !important;
  
  
}
.globe-icon:hover {
  color: #007bff !important;
  
  
}

  .dropdown-toggle:hover {
    color: #007bff; 
  }
  
  .dropdown-menu {
    margin-left: 20px;
    width: 100px;
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 100%;
    right: -30px;
    background-color: white;
    border-radius: 4px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 1000;
  }
  
  .dropdown-menu li {
    margin: 0;
  }
  
  .dropdown-menu button {
    display: flex;
    align-items: center;
    background: none;
    border: none;
    width: 100%;
    padding: 10px;
    text-align: left;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s;
  }
  
  .dropdown-menu button:hover {
    background-color: #007bff; 
  }
  
  .flag-icon {
    width: 20px;
    height: 15px;
    margin-right: 10px;
  }
  