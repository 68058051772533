.contact-container {
  padding: 40px 20px;
  background-color: #f9f9f9;
  text-align: center;
}

.contact-heading {
  font-size: 2.2rem;
  margin-bottom: 35px;
  color: #333;
}

.contact-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;
}

.contact-info {
  max-width: 450px;
  text-align: center; /* Center the text */
}

.contact-text {
  font-size: 1.1rem;
  margin-bottom: 15px;
  color: #555;
}

.contact-details p {
  font-size: 1rem;
  margin-bottom: 8px;
  color: #444;
}

.map-container {
  margin-top: 20px;
  max-width: 700px;
  width: 100%;
  display: flex; /* Center the map */
  justify-content: center;
}

.contact-form {
  max-width: 450px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the form */
}

.contact-input, .contact-textarea {
  width: 100%;
  padding: 12px;
  margin-bottom: 12px;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
}

.contact-input:focus, .contact-textarea:focus {
  border-color: #007BFF;
  outline: none;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.3);
}

.contact-textarea {
  height: 120px;
}

.contact-button {
  padding: 12px;
  background-color: #007BFF;
  color: #fff;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s ease;
}

.contact-button:hover, .contact-button:focus {
  background-color: #0056b3;
}

@media (min-width: 768px) {
  .contact-content {
    flex-direction: row;
    justify-content: center; /* Center the content in row direction */
    gap: 50px; /* Increase gap between contact-info and contact-form */
  }

  .contact-info, .contact-form {
    max-width: 450px;
  }
}
