.Products {
    display: flex;
    align-items: flex-start;
  }
  
  .FilterSidebar {
    width: 1%; /* Adjust as needed */
  }
  
  .ProductDisplay {
    flex-grow: 1;
  
  }
  