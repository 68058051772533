.categories-container {
  padding: 40px 60px;
  color: #333;
}

.categories-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 columns on large screens */
  gap: 20px;
  margin-top: 20px;
}

.category-item {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  overflow: hidden;
  cursor: pointer;
}

.category-item:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.category-image {
  height: 150px;
  background-size: cover;
  background-position: center;
}

.category-item h3 {
  padding: 15px;
  font-size: 18px;
  color: #444;
}

.centered-paragraph {
  
  margin: 0 auto;
  text-align: justify;
  font-size: 1.2rem;
  color: #555;
  line-height: 1.8;
}

h3 {
  font-size: 2rem;
  margin: 0 auto;
  text-align: center;
  color: #222;
}

h2 {
  font-size: 2rem;
  margin: 0 auto;
  text-align: center;
  color: #222;
}

/* Responsive Styles */

/* For screens 1200px and below (medium-large screens) */
@media (max-width: 1200px) {
  .categories-container {
    padding: 30px 40px; /* Adjust padding */
  }
  .categories-grid {
    grid-template-columns: repeat(3, 1fr); /* 3 columns */
  }
  .centered-paragraph, h3, h2 {
    font-size: 1.8rem; /* Slightly smaller text */
  }
}

/* For tablets and medium screens (768px and below) */
@media (max-width: 768px) {
  .categories-container {
    padding: 20px 30px; /* Adjust padding */
    margin-bottom: 20%;

  }
  .categories-grid {
    grid-template-columns: repeat(2, 1fr); /* 2 columns */
  }
  .category-image {
    height: 130px; /* Slightly smaller images */
  }
  .centered-paragraph, h3, h2 {
    font-size: 1.5rem; /* Adjusted font size */
  }
}

/* For small devices (480px and below) */
@media (max-width: 480px) {
  .categories-container {
    padding: 15px 20px; /* More compact padding */
  }
  .categories-grid {
    grid-template-columns: 1fr; /* 1 column */
  }
  .category-image {
    height: 120px; /* Smaller images */
  }
  .centered-paragraph, h3, h2 {
    font-size: 1.2rem; /* Smaller font size for small screens */
  }
}
