.services-container {
  padding: 50px 20px;
  text-align: center;
  position: relative; 
}

.services-heading {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #333;
}

.services-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-bottom: 40px;
}

.service-item {
  width: 300px;
  padding: 1.5%;
  text-align: left;
  background-color: #fff; /* White background to simulate a card */
  border-radius: 12px; /* Rounded corners */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Soft shadow to elevate the card */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Smooth transition on hover */
}

.service-item:hover {
  transform: translateY(-5px); /* Slight lift effect on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2); /* Stronger shadow on hover */
}


.service-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 8px;
}

.service-title {
  font-size: 1.5rem;
  margin: 20px 0 10px;
  color: #444;
}

.service-description {
  font-size: 1rem;
  color: #666;
  line-height: 1.6;
}

.request-quote-button {
  background-color: #000; 
  color: #fff;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 5px;
  transition: background-color 0.3s ease;
  position: absolute; 
  bottom: 20px; 
  left: 50%;
  transform: translateX(-50%); 
}

.request-quote-button:hover {
  background-color: #333; 
}
