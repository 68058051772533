.customer-feedback-container {
  padding: 50px 20px;
  background-color: #f3f3f3;
  text-align: center;
}

.customer-feedback-heading {
  font-size: 2.5rem;
  margin-bottom: 40px;
  color: #333;
}

.feedback-list {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.feedback-item {
  width: 300px;
  margin-bottom: 30px;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  text-align: left;
}

.feedback-image {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 15px;
}

.feedback-name {
  font-size: 1.2rem;
  color: #444;
  margin-bottom: 10px;
}

.feedback-text {
  font-size: 1rem;
  color: #666;
  font-style: italic;
  line-height: 1.6;
}

.star {
  color: gold;
  font-size: 1.5rem;
  margin: 0 2px;
}

.submit-feedback-button {
  background-color: #000;
  color: #fff;
  border: none;
  padding: 15px 30px;
  border-radius: 8px;
  font-size: 1rem;
  cursor: pointer;
  margin-top: 30px;
  transition: background-color 0.3s;
}

.submit-feedback-button:hover {
  background-color: #333;
}
