/* Base Styles */
.products-page {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.product-display-container {
  width: 100%;
  margin-left: auto;
}
/* Add this to your CSS file or styled-components */
.loading-spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh; /* Adjust based on your layout */
  width: 100%;
}

.loading-spinner {
  border: 8px solid #f3f3f3; /* Light grey background */
  border-top: 8px solid black; /* Black spinner */
  border-radius: 50%;
  width: 50px; /* Size of the spinner */
  height: 50px; /* Size of the spinner */
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.product-sort-view {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.no-more-products-message
{
  text-align: center;
  margin-top: 10%;
}
.view-options {
  display: flex;
  align-items: center;
}

.view-options span {
  font-size: 14px;
  color: #333;
  margin-right: 10px;
}

.view-options button {
  background: #f0f0f0;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
  transition: background-color 0.3s, color 0.3s;
}

.view-options button.active {
  background: #000;
  color: #fff;
}

.view-options button i {
  font-size: 16px;
}

.sort-options {
  display: flex;
  align-items: center;
}

.sort-options span {
  font-size: 20px;
  color: #333;
  margin-right: 10px;
}

.sort-options .sort-button {
  background: #f0f0f0;
  border: 1px solid #ddd;
  padding: 10px 20px;
  border-radius: 4px;
  cursor: pointer;
  margin: 0 5px;
  transition: background-color 0.3s, color 0.3s;
}

.sort-options .sort-button.active {
  background: #000;
  color: #fff;
}

.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 30px;
}

.product-card {
  background-color: #efefef;
  border: 0.5px solid #ddd;
  padding: 15px;
  text-align: center;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.product-card img {
  width: 100%;
  height: auto;
  margin-bottom: 15px;
}

.product-card h3 {
  font-size: 16px;
  margin-bottom: 10px;
}

.product-card .price {
  font-size: 14px;
  color: #333;
}

.product-card .original-price {
  text-decoration: line-through;
  color: #999;
  font-size: 12px;
}

.add-to-cart {
  margin-top: 10px;
  padding: 10px 15px;
  background-color: #000;
  color: #fff;
  border: none;
  cursor: pointer;
  font-size: 14px;
  border-radius: 4px;
  transition: background-color 0.3s;
}

.add-to-cart:hover {
  background-color: #333;
}

.shimmer-wrapper {
  position: relative;
  overflow: hidden;
  background-color: #f6f7f8;
  border-radius: 8px;
}

.shimmer {
  width: 100%;
  height: 100%;
  background: linear-gradient(
      to right,
      #f6f7f8 0%,
      #edeef1 20%,
      #f6f7f8 40%,
      #f6f7f8 100%
  );
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}
.load-more-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: black;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}
.load-more-button:hover {
  background-color: #333; 
  transform: scale(1.05); 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}



@keyframes shimmer {
  0% {
      background-position: -200% 0;
  }
  100% {
      background-position: 200% 0;
  }
}

.product-card-shimmer {
  width: 100%;
  height: 300px;
  background-color: #f6f7f8;
  margin: 15px;
  border-radius: 8px;
}
.product-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 30px;
}
.product-list {
  display: flex;
  flex-direction: column;
}

.product-card.list {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #ddd;
  margin-bottom: 10px;
}

.product-card.list img {
  width: 100px;
  height: 100px;
  margin-right: 15px;
}
.description.grid{
  display: none;
  text-align: justify;
}
.description.list{
  text-align: justify;
}
.product-info {
  flex: 1;
  text-align: start;

}

.product-name.list {
  margin: 0;
  text-align: start;
  font-size: 18px;
}

.product-category.list {
  font-size: 14px;
  color: black;
}

.product-category.grid {
  font-size: 14px;
  text-align: center;
  color: black;
}
.button-container.grid {
  display: flex; 
  justify-content: center;
  align-items: center; 
}
.view-button {
  display: flex; 
  justify-content: center; 
  align-items: center;
  margin-top: 10px;
  padding: 6px 5px;
  color: white;
  background-color: #000;
  border-radius: 20px; 
  font-size: 12px; 
  font-weight: bold;
  text-transform: uppercase; 
  border: none; 
  cursor: pointer; 
  transition: background-color 0.3s, transform 0.3s, box-shadow 0.3s; 
}

.view-button:hover {
  background-color: #333; 
  transform: scale(1.05); 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .product-sort-view {
    flex-direction: row;
    align-items: flex-start;
    width: 98%;
  }
  .product-card.list {
    width: 91%;
  }
  .description.list {
    font-size: 12px; 
    text-align: start;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp:3;
    -webkit-line-clamp: 3;
    line-height: 1.5;
  }
  .view-options,
  .sort-options {
    flex-direction: row;
    align-items: flex-start;
    
  }
  .description.grid{
    display: none;
  }
 
  .sort-options .sort-button {
    margin-right: 0;
    margin-bottom: 10px;
    width: 80%;
  }

  .product-grid {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    width: 98%;
  }

  .product-card {
    padding: 10px;
  }
  .description.list {
    font-size: 12px;
    text-align: start; 
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    line-clamp:3;
    -webkit-line-clamp: 3;
    line-height: 1.5; 
  }
  .product-card.list img {
    width: 50% !important ; 
    height:80% !important;
  }

  .product-card h3 {
    font-size: 14px;
  }

  .product-card .price {
    font-size: 12px;
  }

  .product-card .original-price {
    font-size: 10px;
  }

  .product-card.list img {
    width: 80px;
    height: 80px;
  }

  .view-button {
    font-size: 14px;
    padding: 8px 16px;
  }
}

@media (max-width: 425px) {
  .product-sort-view {
    flex-direction: row;
    align-items: flex-start;
    width: 99%;
  }
  .product-card {
    padding: 5px;
  }
  .description.grid{
    display: none;
    
  }
  .product-card img {
    margin-bottom: 5px;
  }

  .product-card h3 {
    font-size: 12px;
  }

  .product-card .price {
    font-size: 10px;
  }

  .product-card .original-price {
    font-size: 8px;
  }

  .product-card.list img {
    width: 60px;
    height: 60px;
  }

  .view-button {
    font-size: 12px;
    padding: 6px 12px;
  }
}
